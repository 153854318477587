<template>
	<BaseLoading v-if="isLoading" />
	<div v-else>
		<BannerCreateAndFilter
			ref="create-and-filter"
			:create-link="createLink"
			:found="meta.total"
			:has-link-to-filter="false"
			:create-button-text="$t('global.general.create', { type: 'SubHeader' })"
			:placeholder-text="$t('global.general.searchPlaceholder', { type: 'SubHeader' })"
			type-found-text="SubHeader"
		/>

		<CRow>
			<CCol>
				<BaseTable
					:pagination="{
						pages: meta.lastPage,
						activePage: meta.currentPage,
					}"
					:fields="tableFields"
					:items="data"
					class="table-custom"
					clickable-rows
					:link-to="editLink"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{
									$t('global.searchNotFound', {
										field: 'banner',
									})
								}}
							</p>
						</div>
					</template>
					<template #status="{item}">
						<CBadge class="badge-status" :color="item.value">
							{{ item.name }}
						</CBadge>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import BannerCreateAndFilter from '@/components/BannerCreateAndFilter.vue';

import { BANNER_STATUSES } from '../enums/banners';
import { SUB_HEADER_TYPES, SUB_HEADER_TABLE_FIELDS } from '../enums/subHeader';

export default {
	name: 'SubHeaderList',

	components: {
		BannerCreateAndFilter,
	},

	props: {
		data: {
			type: Array,
			default: () => [],
		},
		meta: {
			type: Object,
			default: () => {},
		},
		subHeaderType: {
			type: String,
			default: SUB_HEADER_TYPES.HOMEPAGE,
		},
		isLoading: {
			type: Boolean,
			default: true,
		},
		editLink: {
			type: String,
			default: '',
		},
		createLink: {
			type: String,
			default: '',
		},
	},

	data() {
		return {
			BANNER_STATUSES,
		};
	},

	computed: {
		tableFields() {
			return SUB_HEADER_TABLE_FIELDS;
		},
	},

	methods: {
		handlePageChange(page) {
			this.$refs['create-and-filter'].handlePageChange(page);
		},
	},
};
</script>
