<template>
	<div class="tabs-container">
		<div class="tabs-wrapper">
			<CTabs>
				<CTab
					v-for="(item, index) in components"
					:key="index"
					:title="item.title"
					:active="item.routerTo === $router.currentRoute.name"
					:to="{ name: item.routerTo }"
				>
					<div class="c-main">
						<div class="container-fluid">
							<component
								:is="item.component"
								:is-loading="isLoading"
								:data="getSubHeaderData"
								:meta="getSubHeaderMeta"
								:create-link="item.routerToCreate"
								:edit-link="item.routerToEdit"
								:sub-header-type="subHeaderTypes"
							/>
						</div>
					</div>
				</CTab>
			</CTabs>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import SubHeaderList from '@/components/SubHeaderList.vue';
import { SUB_HEADER_TABS, SUB_HEADER_COMPONENTS, SUB_HEADER_TYPES } from '../enums/subHeader';

export default {
	name: 'SubHeaders',
	components: {
		SubHeaderList,
	},
	props: {
		subHeaderType: {
			type: String,
			default: SUB_HEADER_TYPES.HOMEPAGE,
		},
	},
	data() {
		return {
			queryParams: {
				q: this.$route.query.q || null,
				is_content_visible: this.$route.query.is_visible || null,
				start_at: this.$route.query.start_at || null,
				end_at: this.$route.query.end_at || null,
				page: Number(this.$route.query.page) || null,
				per_page: Number(this.$route.query.per_page) || null,
			},
		};
	},
	computed: {
		...mapState('subHeaders', {
			list: 'list',
		}),
		...mapGetters({
			getSubHeaderList: 'subHeaders/getSubHeaderList',
			getSubHeaderMeta: 'subHeaders/getSubHeaderMeta',
		}),
		components() {
			return SUB_HEADER_TABS.map((tab) => SUB_HEADER_COMPONENTS[tab]);
		},
		subHeaderTypes() {
			return SUB_HEADER_TYPES[this.subHeaderType.toUpperCase()];
		},
		getSubHeaderData() {
			return this.getSubHeaderList;
		},
		isLoading() {
			return this.list.isLoading;
		},
	},
	async created() {
		const params = {
			...this.queryParams,
			type: this.subHeaderType,
		};
		await this.getSubHeaders(params);
	},
	methods: {
		...mapActions({
			getSubHeaders: 'subHeaders/getSubHeaders',
		}),
	},
};
</script>
